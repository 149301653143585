<template>
<div class="contactus-container" :style="{height: innerHeight + 'px'}">
    <h1>Contatti</h1>
    <vs-row justify="flex-start" class="top">
        <vs-row direction="column" style="gap: 20px;" class="heading">
            <img src="../assets/images/contatti.png" class="contact-us" />
            <p>{{ $t('contactus.contacts.caption') }}</p>
            <vs-row justify="flex-start" align="center" style="gap: 5px;">
                <img src="../assets/icons/email.png" style="width: 16px;"/>
                <p>info@tourial.it</p>
            </vs-row>
        </vs-row>

    </vs-row>
    <div class="bottom">
        <vs-row direction="column" style="gap: 20px;">
            <p style="font-size: 1.2rem; color: white;">{{ $t('contactus.socials.caption') }}</p>
            <vs-row style="gap: 30px;">
                <img style="cursor: pointer;" @click="openUrl('https://www.facebook.com/profile.php?id=100090998845042')" src="../assets/icons/fb.png" />
                <img style="cursor: pointer;" @click="openUrl('https://instagram.com/tourial.it')" src="../assets/icons/ig.png" />
                <img style="cursor: pointer;" @click="openUrl('https://www.tiktok.com/@tourial.it')" src="../assets/icons/tt.png" />
                <img style="cursor: pointer;" @click="openUrl('https://www.youtube.com/@Tourial.it.')" src="../assets/icons/yt.png" />
            </vs-row>
        </vs-row>
        <div class="form">
            <div class="header">
                <h3>{{ $t('contactus.form.title') }}</h3>
                <p>{{ $t('contactus.form.caption') }}</p>
            </div>
            <vs-input block :placeholder="$t('common.name')" v-model="name"/>
            <vs-input block :placeholder="$t('common.email')" v-model="email" type="email"/>
            <textarea block :placeholder="$t('contactus.form.placeholder')" v-model="text" style="resize: none; background: #f5f7f8; border: none;"></textarea>
            <vs-button block size="large" @click="contactRequest">{{ $t('contactus.form.button') }}</vs-button>
        </div>
    </div>

</div>
</template>

<script>
import { apiCall } from '../utils/ApiMiddleware';
export default {
    data() {
        return {
            name: '',
            email: '',
            text: ''
        }
    },

    props: {
        user: Object,
        innerHeight: {
            type: Number,
            default: 100,
            required: false
        }
    },

    mounted() {
        if(this.user != null){
            this.name = this.user.name;
            this.email = this.user.email;
        }
    },

    methods: {
        openUrl(url){
            window.open(url, '_blank');
        },
        async contactRequest(){
            // verify that email address is not empty and is valid
            if(this.email == '' || !this.email.includes('@')){
                this.$vs.notification({
                    title: 'Errore!',
                    text: 'Inserisci un indirizzo email valido.',
                    color: 'danger',
                    position: 'top-right'
                })
                return;
            }

            // verify that name is not empty
            if(this.name == ''){
                this.$vs.notification({
                    title: 'Errore!',
                    text: 'Inserisci il tuo nome.',
                    color: 'danger',
                    position: 'top-right'
                })
                return;
            }

            // verify that the name field is valid and contains letters only
            if(!/^[a-zA-Z ]+$/.test(this.name)){
                this.$vs.notification({
                    title: 'Errore!',
                    text: 'Il nome inserito non è valido.',
                    color: 'danger',
                    position: 'top-right'
                })
                return;
            }

            // verify that text is not empty
            if(this.text == ''){
                this.$vs.notification({
                    title: 'Errore!',
                    text: 'Inserisci il testo della richiesta.',
                    color: 'danger',
                    position: 'top-right'
                })
                return;
            }
            const loading = this.$vs.loading();
            const response = await apiCall('POST', '/contacts', {
                name: this.name,
                email: this.email,
                text: this.text
            });
            if (response.status == 200) {
                // show a success message
                this.$vs.notification({
                    title: 'Richiesta inviata!',
                    text: 'La tua richiesta è stata inviata con successo, ti risponderemo al più presto.',
                    color: 'success',
                    position: 'top-right'
                })
                
            } else if (response.status != 0) {
                // show an error message
                this.$vs.notification({
                    title: 'Errore!',
                    text: 'Si è verificato un errore durante l\'invio della richiesta, riprova più tardi.',
                    color: 'danger',
                    position: 'top-right'
                })
            }
            loading.close();
        }
    },

    watch: {
        user(n){
            if(n != null){
                this.name = this.user.name;
                this.email = this.user.email;
            }
        }
    },
}
</script>

<style scoped>
.contactus-container {
    display: flex;
    padding-top: 50px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: #F7F5F9;
    gap: 20px;

}

.bottom {
    background: var(--primary);
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    padding-bottom: 50px;
    padding-right: 15vw;
    padding-left: 15vw;
    padding-top: 30px;
    box-sizing: border-box;
}

.top {
    padding-left: 15vw;
}

.form {
    background: #FFFFFF;
    /* Dropshadow 1 */
    box-sizing: border-box;

    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    border-radius: 15px;
    padding: 30px;
    width: 30vw;
    max-width: 30vw;
    gap: 20px;
    display: flex;
    flex-direction: column;
    margin-top: -400px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.form .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

textarea {
    border-radius: 5px;
    border: 1px solid var(--grey-75);
    height: 150px;
    max-height: 150px;
    padding: 10px;
    font-family: Poppins;
}

img.contact-us {
    width: 30%;
}

.card img {
    width: 150px;
}

p,
h1,
h2,
h3 {
    margin: 0;
}



/* MOBILE */
@media (max-width: 480px) {

    .contactus-container {
        /*padding-top: 80px !important;*/
        padding-bottom: 80px;
    }

    .form {
        margin-top: -150px;
        max-width: unset;
        width: 100%;

    }

    .bottom {
        flex-direction: column-reverse;
        padding-left: 5vw;
        padding-right: 5vw;
        margin-top: 120px;
        gap: 30px;
        padding-bottom: 200px;
    }

    .heading {
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
        
        margin-bottom: 20px;
    }

    .top {
        padding-left: 5vw;
        padding-right: 5vw;
    }

    img.contact-us {
        width: 100%;
    }
}

@media only screen and (min-width : 480px)
   and (max-width : 820px) {
    .contactus-container {
        padding-left: 50px!important;
        box-sizing: border-box;
    }

    .form {
        margin-top: -150px;
        max-width: unset;
        width: 100%;

    }

    .bottom {
        flex-direction: column-reverse;
        padding-left: 5vw;
        padding-right: 5vw;
        margin-top: 120px;
        gap: 30px;
        padding-bottom: 200px;
    }

    .heading {
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
        
        margin-bottom: 20px;
    }

    .top {
        padding-left: 5vw;
        padding-right: 5vw;
    }

    img.contact-us {
        width: 100%;
    }
}

.tourial-app.tourial-native .contactus-container {
    padding-bottom: 110px!important;
    padding-top: 110px!important;
}

.tourial-app.tourial-native .bottom {
    padding-bottom: 290px!important;
}
</style>
